import React, { Component } from 'react'
import './PrivacyPolicy.css'
import Aside from '../components/Aside'
import Content from '../components/Content'
import Footer from '../components/Footer'
import Navigation from '../components/Navigation'


export default class DeliveryScreen extends Component {
  render() {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    return (
      <div>
        <Aside>
          <Navigation/>
        </Aside>
        <Content>
          <h2>Bulgaria orders</h2>
          <p>
            Orders within <strong>Bulgaria</strong> are shipped with Econt Express.
            Average time for delivery is between 2 and 7 working days from order confirmation.
            Shipping costs <strong>are not</strong> included in the final price.
            For more information and shipping costs please check <strong><a href="http://www.econt.com/tariff-calculator/" className="kypbn-font delivery-link">here</a></strong>
          </p>
          <h2>International orders</h2>
          <p>
            International orders are shipped with Bulgarian posts. Average time for
            delivery is between 1 and 2 weeks from order confirmation. Shipping costs
            <strong>are not</strong> included in the final price. For more information
            and shipping costs please check <strong><a href="http://www.bgpost.bg/en/calculator?type_package=4&destination=2&find=Calculate" class="kypbn-font delivery-link">here</a></strong>
          </p>
        </Content>
        <Footer/>
      </div>
    )
  }
}
