import React, { Component } from 'react'
import Button from './Button'
import client from '../client/client'

import './CartShipping.css'

// ORDER TYPE
const UNKNOWN = -1
const LOCAL = 0
const FOREIGN = 1

// PAYPAL ORDER STATUS
const PAYPAL_COMPLETED = 'COMPLETED'

class CartShipping extends Component {
  constructor(props) {
    super(props)
    this.state = {
      shipping: UNKNOWN,
      foreignShown: false,
      firstName: '',
      lastName: '',
      email: '',
      country: '',
      city: '',
      address: '',
      phone: '',
      discountCode: '',
      discount: 0,
    }
  }

  getProducts() {
    return this.props.cart.products.map((p) => {
      return {
        pid: p.id,
        size: p.size,
        quantity: p.qty,
      }
    })
  }

  getTotal() {
    if (this.props.cart.products.length === 0) {
      return 0
    }

    const productsPrice = this.props.cart.products
      .map(p => p.price)
      .reduce((a, c) => a + c)

    let total = productsPrice
    if (this.state.discount > 0) {
      total -= total * (this.state.discount / 100)
    }

    return total.toFixed(2)
  }

  getLocalOrder() {
    return {
      order_type: LOCAL,
      customer: {
        fname: this.state.firstName,
        lname: this.state.lastName,
        email: this.state.email,
        phone: this.state.phone,
      },
      shipping: {
        city: this.state.city,
        addr1: this.state.address,
        additional_info: this.state.discountCode,
      },
      products: this.getProducts(),
      total: this.getTotal(),
    };
  }

  getForeignOrder(data) {
    return {
      order_type: FOREIGN,
      payment_id: data.id,
      customer: {
        fname: data.payer.name.given_name,
        lname: data.payer.name.surname,
        email: data.payer.email_address,
      },
      shipping: {
        country: data.purchase_units[0].shipping.address.country_code,
        city: data.purchase_units[0].shipping.address.admin_area_2,
        addr1: data.purchase_units[0].shipping.address.address_line_1,
        addr2: data.purchase_units[0].shipping.address.address_line_2,
        postal_code: data.purchase_units[0].shipping.address.postal_code,
      },
      products: this.getProducts(),
      total: this.getTotal(),
    }
  }

  validateInput() {
    return this.getTotal() > 0 &&
      this.state.firstName.length > 0 &&
      this.state.lastName.length > 0 &&
      this.state.email.length > 0 &&
      this.state.city.length > 0 &&
      this.state.address.length > 0 &&
      this.state.phone.length > 0
  }

  async onDiscountChange(code) {
    this.setState({ discountCode: code})

    if (code.length >= 8) {
      const resp = await client.get(`/discount/${code}`)
      this.setState({ discount: resp.discount })
    }
  }

  async onLocalCheckout() {
    if (!this.validateInput()) {
      console.error('Failed to validate input')
      return
    }

    this.props.toggleLoader()

    const order = this.getLocalOrder()
    console.log(order)

    try {
      const resp = await client.post('/purchase', order)
      if (resp.ok) {
        this.props.cart.clear()
        this.props.toggleLoader()
        window.location.href = '/purchase'
      }
    } catch (e) {
      console.error(e)
      // show notification here
    }
  }

  async onForeignCheckout(details) {
    const order = this.getForeignOrder(details)
    console.log('FOREIGN ORDER', order)

    this.props.toggleLoader()

    try {
      const resp = await client.post('/purchase', order)
      if (resp.ok) {
        this.props.cart.clear()
        this.props.toggleLoader()
        window.location.href = '/purchase'
      }
    } catch (e) {
      console.error(e)
      // show notification here
    }
  }

  shippingDestination() {
    return (
      <div className='shipping-wrapper'>
        <div className='shipping-btn-wrapper'>
          <Button
            className='shipping-btn'
            value={'BULGARIA'}
            onClick={() => {
              this.setState({
                shipping: LOCAL
              })
          }}/>
        </div>
        <div className='shipping-btn-wrapper'>
            <Button
              className='shipping-btn'
              value={'INTERNATIONAL'}
              onClick={() => {
                this.setState({
                  shipping: FOREIGN
                })
            }}/>
        </div>
      </div>
    )
  }

  preparePayPalProducts() {
    return this.props.cart.products.map((p) => {
      return {
        'name': p.name,
        'quantity': p.quantity,
        'unit_amount': p.price,
        'description': p.size,
      }
    })
  }

  getPayPalConfig() {
    return {
      commit: true,

      style: {
        layout: 'vertical',
        color: 'black',
        shape: 'rect',
        height: 45,
        label: 'paypal',
        tagline: false
      },

      createOrder: (data, actions) => {
        const total = this.getTotal().toString()
        return actions.order.create({
          intent: 'CAPTURE',
          purchase_units: [{
            amount: {
              value: total,
            },
          }]
        });
      },

      onApprove: (data, actions) => {
        // Capture the funds from the transaction
        return actions.order.capture().then((details) => {
          if (details.status === PAYPAL_COMPLETED) {
            this.onForeignCheckout(details)
          } else {
            alert('Something went wrong and your transaction failed. Please try again')
            console.log(details)
          }
        });
      },
    }
  }

  shippingLocal() {
    const fn = this.state.firstName.length > 0 ? 'input' : 'input invalid';
    const ln = this.state.lastName.length > 0 ? 'input' : 'input invalid';
    const email = this.state.email.length > 0 ? 'input' : 'input invalid';
    const city = this.state.city.length > 0 ? 'input' : 'input invalid';
    const addr = this.state.address.length > 0 ? 'input' : 'input invalid';
    const phone = this.state.phone.length > 0 ? 'input' : 'input invalid';
    return (
      <div>
        <input className={fn}
          value={this.state.firstName}
          onChange={(e) => { this.setState({ firstName: e.target.value}) }}
          type='text' name='firstname' placeholder='FIRST NAME'/>
        <input className={ln}
          value={this.state.lastName}
          onChange={(e) => { this.setState({ lastName: e.target.value}) }}
          type='text' name='lastName' placeholder='LAST NAME'/>
        <input className={email}
          value={this.state.email}
          onChange={(e) => { this.setState({ email: e.target.value}) }}
          type='text' name='email' placeholder='EMAIL'/>
        <input className={city}
          value={this.state.city}
          onChange={(e) => { this.setState({ city: e.target.value}) }}
          type='text' name='city' placeholder='CITY'/>
        <input className={addr}
          value={this.state.address}
          onChange={(e) => { this.setState({ address: e.target.value}) }}
          type='text' name='address' placeholder='ADDRESS'/>
        <input className={phone}
          value={this.state.phone}
          onChange={(e) => { this.setState({ phone: e.target.value}) }}
          type='text' name='phone' placeholder='PHONE'/>
        <input className='input'
          value={this.state.discountCode}
          onChange={(e) => { this.onDiscountChange(e.target.value) }}
          type='text' name='discount' placeholder='DISCOUNT CODE'/>
        <div className='checkout-btn'>
          <Button onClick={() => { this.onLocalCheckout() }} value={'CHECKOUT'}/>
        </div>
      </div>
    )
  }

  shippingForeign() {
    if (!this.state.foreignShown) {
      setTimeout(() => {
        let paypalConfig = this.getPayPalConfig()
        window.paypal.Buttons(paypalConfig).render('#paypal-button-wrapper');
      }, 300)
      this.setState({ foreignShown: true })
    }

    return (
      <div>
        <div id='paypal-button-wrapper'></div>
        <p className='payment-text'>
          * For credit/debit card payment<br/>
          please select one of the <br/>
          VISA/MasterCard/AMEX icons
        </p>
        <input className='input'
          value={this.state.discountCode}
          onChange={(e) => { this.onDiscountChange(e.target.value) }}
          type='text' name='discount' placeholder='DISCOUNT CODE'/>
      </div>
    )
  }

  render() {
    const currency = (this.props.products[0] || {}).currency
    let price = this.getTotal()
    const priceStyle = price > 0 ? 'cart-total-price' : 'cart-total-price-invalid';

    return (
      <div>
        <h2 className={priceStyle}>Total: {price} {currency}</h2>
        { this.state.shipping === UNKNOWN ?
            this.shippingDestination() :
              this.state.shipping === LOCAL ?
                this.shippingLocal() : this.shippingForeign() }
      </div>
    )
  }
}

export default CartShipping
